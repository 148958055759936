import React from "react";
import styled from "styled-components";
//
import Iframe from "react-iframe";
import { RichText } from "components/prismic";
//
import Support from "./support";
//
export default ({
  description,
  image,
  link,
  logo,
  subtitle,
  support,
  support_text,
  title,
  ...rest
}) => (
  <Wrap>
    <img src={image.url} alt="takriko" />
    <Top>
      <img src={logo.url} alt="takriko" />
      <Title>
        <h1>{title}</h1> <h2>{subtitle}</h2>
      </Title>
    </Top>
    <Bottom>
      <RichText {...description} />
      <Embed>
        <Iframe url={link.url} width="100%" height="100%" frameBorder="0" />
      </Embed>
      <RichText {...support_text} />
      <List>
        {support.map((support) => (
          <Support key={support.support_title} {...support} />
        ))}
      </List>
    </Bottom>
  </Wrap>
);
//
const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }
  h4 {
    margin: 0;
    padding: 0 1rem;
    @media (min-width: 769px) {
      padding: 1rem 5rem 0;
    }
  }
`;
const Top = styled.div`
  background-color: #ffff;
  border-bottom: 1px solid #d9dce4;
  display: flex;
  justify-content: center;
  margin: 0 0.5rem;
  min-height: 3rem;
  min-height: calc(3rem + 2px);
  padding: 0.25rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  @media (min-width: 769px) {
    justify-content: flex-start;
    margin: 0 1rem;
    padding: 0.5rem 1rem 0.5rem 4rem;
    top: 0;
  }

  img {
    display: none;
    height: 100%;
    margin: 0.5rem;
    width: 100px;

    @media (min-width: 769px) {
      display: block;
      width: 80px;
    }
  }
`;

const Bottom = styled.div`
  display: grid;
  row-gap: 1rem;
  padding: 1rem;

  @media (min-width: 769px) {
    padding: 1rem 5rem;
  }
`;
const Embed = styled.div`
  border: 1px solid #d9dce4;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 75vh;
  height: calc(75vh - 65px);
`;
const Title = styled.div`
  h1 {
    align-self: flex-end;
    font-size: 0.67em;
    justify-self: center;
    padding: 0.3rem 0 0 0;
    text-align: center;
  }
  h2 {
    display: none;
  }

  @media (min-width: 500px) {
    h1 {
      font-size: 1.1em;
    }
  }
  @media (min-width: 769px) {
    h1 {
      font-size: 1.6em;
      margin: 1.2rem 0 0 0.5rem;
      text-align: left;
      padding: 0;
    }
    h2 {
      color: #213e65;
      display: block;
      font-family: "Playfair Display", serif;
      font-size: 1.3em;
      margin: 0 0.5rem;
    }
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 769px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
